import React, { useContext, useEffect, useState } from "react";
import ImageUploading from 'react-images-uploading';
import Block from "../../layout/Blocks";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from "react-i18next";
import { Button, Checkbox, TextField } from "@mui/material";
import MediaQuery from "react-responsive";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/StoreReducer";
import { Controller, useFormContext } from "react-hook-form";
import UploadRules from "../UploadRules";

const Reasons = () => {

    const { t } = useTranslation();

    const [images, setImages] = React.useState([]);
    const [images2, setImages2] = React.useState([]);
    const [images3, setImages3] = React.useState([]);
    const [images4, setImages4] = React.useState([]);

    const { setValue, trigger, control, formState: { errors, isSubmitted } } = useFormContext();

    const [regUploadedFiles, setRegUploadedFiles] = useState({ 0: [], 1: [], 2: [], 3: [] });
    const [maxNumber, setMaxNumber] = useState(11);

    const config = {
        maxNumber: 10,
        maxFileSize: 10000000,
        acceptType: ["jpg", "jpeg", "png", "pdf", "doc", "docx", "zip"]
    };

    const [store, dispatch] = useContext(StoreContext);
    const { form } = store;
    const { submitted } = form;

    const initialStore = {
        0: false,
        1: false,
        2: false,
        3: false,
    };

    const [checkedReasons, setCheckedReasons] = useState(initialStore);

    const handleChange = (panel) => {

        console.log('handleChange', panel);
        console.warn(
            !checkedReasons[panel]
        );

        setCheckedReasons({ ...checkedReasons, [panel]: !checkedReasons[panel] });
    }

    useEffect(() => {

        let parsedReason = '';

        for (const prop in checkedReasons) {
            if (checkedReasons[prop]) {
                parsedReason += t(`index.reasons.${prop}`) + ',';
            }
        }

        if (parsedReason.length >= 0) {
            const formattedReasons = parsedReason.slice(0, -1);
            dispatch({
                type: types.setForm,
                payload: {
                    ...form,
                    reasons: formattedReasons
                }
            });

            setValue('reasons', formattedReasons, { shouldValidate: true });
            if (submitted) {
                trigger('reasons');
            }
        }
    }, [checkedReasons]);


    const handleCheck = (e, panel) => {
        setCheckedReasons({ ...checkedReasons, [panel]: e.target.checked });
    };

    useEffect(() => {

        let total = 11;
        let current = 0;

        for (const prop in regUploadedFiles) {
            current = current + regUploadedFiles[prop].length;
        };

        setMaxNumber(() => total - current);

        const files = [];
        const reasonLabels = ['medical_assistance', 'odontology', 'medicines', 'other'];

        for (let i = 0; i <= 3; i++) {

            if (regUploadedFiles[i].length > 0) {
                regUploadedFiles[i].map((file, idx) => {
                    files.push({ name: `files[${reasonLabels[i]}][${idx}]`, file: file });
                });
            }
        }

        if (files.length > 0) {
            dispatch({
                type: types.setForm,
                payload: {
                    ...form,
                    files: files
                }
            });
        }


    }, [regUploadedFiles])


    const registerFiles = (imageList, idx) => {

        const files = [];

        imageList.forEach((image) => {
            files.push(image?.file);
        });

        setRegUploadedFiles({ ...regUploadedFiles, [idx]: files });
    }

    const onChange = (imageList, addUpdateIndex) => {
        registerFiles(imageList, 0);
        setImages(imageList);
    };

    const onChange2 = (imageList, addUpdateIndex) => {
        registerFiles(imageList, 1);
        setImages2(imageList);
    };

    const onChange3 = (imageList, addUpdateIndex) => {
        registerFiles(imageList, 2);
        setImages3(imageList);
    };

    const onChange4 = (imageList, addUpdateIndex) => {
        registerFiles(imageList, 3);
        setImages4(imageList);
    };

    return (
        <Block mode="content" title={t("index.reasonTitle")}>
            <div>
                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={config.maxNumber}
                    maxFileSize={config.maxFileSize}
                    dataURLKey="data_url"
                    acceptType={config.acceptType}
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        <>
                            <Accordion elevation={0} disableGutters>
                                <AccordionSummary
                                    onClick={() => handleChange(0)}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        padding: "0 0.25rem", margin: 0,
                                        "& .MuiAccordionSummary-content": {
                                            margin: "0"
                                        }

                                    }}
                                >
                                    <div className="px-[0.5rem] md:px-0 flex items-center justify-start">
                                        <Checkbox
                                            checked={checkedReasons[0]}
                                            onChange={(e) => handleCheck(e, 0)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        {t("index.reasons.0")}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <h2 className="font-titillium text-base font-bold text-blue-secondary px-0 sm:px-3">{t("index.reasonsData.subtitle")}</h2>
                                    <ul className="list-disc space-y-1 sm:space-y-2 text-sm my-4 ml-4 px-0 sm:px-3">
                                        <li>{t("index.reasonsData.uploadRules.1")}</li>
                                        <li>{t("index.reasonsData.uploadRules.2")}</li>
                                        <li>{t("index.reasonsData.uploadRules.3")}</li>
                                    </ul>
                                    <div className="upload__image-wrapper">
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item flex items-center justify-start">
                                                <div className="image__container ml-0 sm:ml-4 mr-2 sm:mr-8">
                                                    <img src={image['data_url']} alt="" />
                                                </div>
                                                <div className="image-item__btn-wrapper">
                                                    <button onClick={() => onImageUpdate(index)} className="btn__update">{t("index.reasonsData.update")}</button>
                                                    <button onClick={() => onImageRemove(index)} className="btn__remove">{t("index.reasonsData.delete")}</button>
                                                </div>
                                            </div>
                                        ))}

                                        <Button
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            sx={{
                                                backgroundColor: isDragging ? "#EEEEEE" : "#2F6DE0",
                                                color: isDragging ? "#888" : "#FFF",
                                                border: isDragging ? "2px dashed #999" : "2px solid #2F6DE0",

                                            }}
                                            className="btn-core my-4 max-w-80 font-sans font-medium ml-0 sm:ml-3"
                                            variant="contained"
                                            disableElevation
                                            fullWidth>
                                            {isDragging ? t("index.reasonsData.dragHere") :
                                                <>
                                                    <MediaQuery minWidth={620}>{t("index.reasonsData.selectOrDrag")}</MediaQuery>
                                                    <MediaQuery maxWidth={619}>{t("index.reasonsData.selectFiles")}</MediaQuery>
                                                </>
                                            }
                                        </Button>
                                        <UploadRules config={config} errors={errors} />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )}
                </ImageUploading>

                <ImageUploading
                    multiple
                    value={images2}
                    onChange={onChange2}
                    maxNumber={config.maxNumber}
                    maxFileSize={config.maxFileSize}
                    dataURLKey="data_url"
                    acceptType={config.acceptType}
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        <>
                            <Accordion
                                elevation={0}
                                disableGutters>
                                <AccordionSummary
                                    onClick={() => handleChange(1)}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    sx={{
                                        padding: "0 0.25rem", margin: 0,
                                        "& .MuiAccordionSummary-content": {
                                            margin: "0"
                                        }

                                    }}
                                >
                                    <div className="px-[0.5rem] md:px-0 flex items-center justify-start">
                                        <Checkbox
                                            checked={checkedReasons[1]}
                                            onChange={(e) => handleCheck(e, 1)}
                                            // checked={checked[2]}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        {t("index.reasons.1")}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <h2 className="font-titillium text-base font-bold text-blue-secondary px-0 sm:px-3">{t("index.reasonsData.subtitle")}</h2>
                                    <ul className="list-disc space-y-1 sm:space-y-2 text-sm my-4 ml-4 px-0 sm:px-3">
                                        <li>{t("index.reasonsData.uploadRules.1")}</li>
                                        <li>{t("index.reasonsData.uploadRules.2")}</li>
                                        <li>{t("index.reasonsData.uploadRules.3")}</li>
                                    </ul>
                                    <div className="upload__image-wrapper">
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item flex items-center justify-start">
                                                <div className="image__container ml-0 sm:ml-4 mr-2 sm:mr-8">
                                                    <img src={image['data_url']} alt="" />
                                                </div>
                                                <div className="image-item__btn-wrapper">
                                                    <button onClick={() => onImageUpdate(index)} className="btn__update">{t("index.reasonsData.update")}</button>
                                                    <button onClick={() => onImageRemove(index)} className="btn__remove">{t("index.reasonsData.delete")}</button>
                                                </div>
                                            </div>
                                        ))}

                                        <Button
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            sx={{
                                                backgroundColor: isDragging ? "#EEEEEE" : "#2F6DE0",
                                                color: isDragging ? "#888" : "#FFF",
                                                border: isDragging ? "2px dashed #999" : "2px solid #2F6DE0",

                                            }}
                                            className="my-4 max-w-80 font-sans font-medium ml-0 sm:ml-3"
                                            variant="contained"
                                            disableElevation
                                            fullWidth>
                                            {isDragging ? t("index.reasonsData.dragHere") :
                                                <>
                                                    <MediaQuery minWidth={620}>{t("index.reasonsData.selectOrDrag")}</MediaQuery>
                                                    <MediaQuery maxWidth={619}>{t("index.reasonsData.selectFiles")}</MediaQuery>
                                                </>
                                            }
                                        </Button>
                                        <UploadRules config={config} errors={errors} />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )}
                </ImageUploading>

                <ImageUploading
                    multiple
                    value={images3}
                    onChange={onChange3}
                    maxNumber={config.maxNumber}
                    maxFileSize={config.maxFileSize}
                    dataURLKey="data_url"
                    acceptType={config.acceptType}
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        <>
                            <Accordion
                                elevation={0}
                                disableGutters>
                                <AccordionSummary
                                    onClick={() => handleChange(2)}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    sx={{
                                        padding: "0 0.25rem", margin: 0,
                                        "& .MuiAccordionSummary-content": {
                                            margin: "0"
                                        }

                                    }}
                                >
                                    <div className="px-[0.5rem] md:px-0 flex items-center justify-start">
                                        <Checkbox
                                            checked={checkedReasons[2]}
                                            onChange={(e) => (e, 2)}
                                            // checked={checked[3]}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        {t("index.reasons.2")}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <h2 className="font-titillium text-base font-bold text-blue-secondary px-0 sm:px-3">{t("index.reasonsData.subtitle")}</h2>
                                    <ul className="list-disc space-y-1 sm:space-y-2 text-sm my-4 ml-4 px-0 sm:px-3">
                                        <li>{t("index.reasonsData.uploadRules.1")}</li>
                                        <li>{t("index.reasonsData.uploadRules.2")}</li>
                                        <li>{t("index.reasonsData.uploadRules.3")}</li>
                                    </ul>
                                    <div className="upload__image-wrapper">
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item flex items-center justify-start">
                                                <div className="image__container ml-0 sm:ml-4 mr-2 sm:mr-8">
                                                    <img src={image['data_url']} alt="" />
                                                </div>
                                                <div className="image-item__btn-wrapper">
                                                    <button onClick={() => onImageUpdate(index)} className="btn__update">{t("index.reasonsData.update")}</button>
                                                    <button onClick={() => onImageRemove(index)} className="btn__remove">{t("index.reasonsData.delete")}</button>
                                                </div>
                                            </div>
                                        ))}

                                        <Button
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            sx={{
                                                backgroundColor: isDragging ? "#EEEEEE" : "#2F6DE0",
                                                color: isDragging ? "#888" : "#FFF",
                                                border: isDragging ? "2px dashed #999" : "2px solid #2F6DE0",

                                            }}
                                            className="my-4 max-w-80 font-sans font-medium ml-0 sm:ml-3"
                                            variant="contained"
                                            disableElevation
                                            fullWidth>
                                            {isDragging ? t("index.reasonsData.dragHere") :
                                                <>
                                                    <MediaQuery minWidth={620}>{t("index.reasonsData.selectOrDrag")}</MediaQuery>
                                                    <MediaQuery maxWidth={619}>{t("index.reasonsData.selectFiles")}</MediaQuery>
                                                </>
                                            }
                                        </Button>
                                        <UploadRules config={config} errors={errors} />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )}
                </ImageUploading>

                <ImageUploading
                    multiple
                    value={images4}
                    onChange={onChange4}
                    maxNumber={config.maxNumber}
                    maxFileSize={config.maxFileSize}
                    dataURLKey="data_url"
                    acceptType={config.acceptType}
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        <>
                            <Accordion
                                elevation={0}
                                disableGutters>
                                <AccordionSummary
                                    onClick={() => handleChange(3)}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                    sx={{
                                        padding: "0 0.25rem", margin: 0,
                                        "& .MuiAccordionSummary-content": {
                                            margin: "0"
                                        }

                                    }}
                                >
                                    <div className="px-[0.5rem] md:px-0 flex items-center justify-start">
                                        <Checkbox
                                            checked={checkedReasons[3]}
                                            onChange={(e) => handleCheck(e, 3)}
                                            // checked={checked[4]}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        {t("index.reasons.3")}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <h2 className="font-titillium text-base font-bold text-blue-secondary px-0 sm:px-3">{t("index.reasonsData.subtitle")}</h2>
                                    <ul className="list-disc space-y-1 sm:space-y-2 text-sm my-4 ml-4 px-0 sm:px-3">
                                        <li>{t("index.reasonsData.uploadRules.1")}</li>
                                        <li>{t("index.reasonsData.uploadRules.2")}</li>
                                        <li>{t("index.reasonsData.uploadRules.3")}</li>
                                    </ul>
                                    <div className="upload__image-wrapper">
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item flex items-center justify-start">
                                                <div className="image__container ml-0 sm:ml-4 mr-2 sm:mr-8">
                                                    <img src={image['data_url']} alt="" />
                                                </div>
                                                <div className="image-item__btn-wrapper">
                                                    <button onClick={() => onImageUpdate(index)} className="btn__update">{t("index.reasonsData.update")}</button>
                                                    <button onClick={() => onImageRemove(index)} className="btn__remove">{t("index.reasonsData.delete")}</button>
                                                </div>
                                            </div>
                                        ))}

                                        <Button
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            sx={{
                                                backgroundColor: isDragging ? "#EEEEEE" : "#2F6DE0",
                                                color: isDragging ? "#888" : "#FFF",
                                                border: isDragging ? "2px dashed #999" : "2px solid #2F6DE0",

                                            }}
                                            className="my-4 max-w-80 font-sans font-medium ml-0 sm:ml-3"
                                            variant="contained"
                                            disableElevation
                                            fullWidth>
                                            {isDragging ? t("index.reasonsData.dragHere") :
                                                <>
                                                    <MediaQuery minWidth={620}>{t("index.reasonsData.selectOrDrag")}</MediaQuery>
                                                    <MediaQuery maxWidth={619}>{t("index.reasonsData.selectFiles")}</MediaQuery>
                                                </>
                                            }
                                        </Button>
                                        <UploadRules config={config} errors={errors} />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )}
                </ImageUploading>

                <div className="py-2 px-[24px] h-[20px] -mt-[10px] ml-0 md:-ml-[10px]">
                    {!errors?.reasons && maxNumber === 11 && isSubmitted && <span className="text-[#d32f2f] text-xs">{t("form.errors.upload")}</span>}
                    <Controller
                        controller={control}
                        name={"reasons"}
                        rules={{ required: t("form.errors.required") }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type="hidden"
                                size="small"
                                error={errors?.reasons && isSubmitted}
                                helperText={errors?.reasons && isSubmitted && (errors?.reasons?.message)}
                                fullWidth
                                hiddenLabel
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        )}
                    />
                </div>
            </div>
        </Block>
    )
}

export default Reasons;