import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Block from "../../layout/Blocks";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/StoreReducer";
import { Controller, useFormContext } from "react-hook-form";

const TermsAndConditions = ({ tacTarget, loginState }) => {

    const [store, dispatch] = useContext(StoreContext)
    const [checked, setChecked] = useState(false);
    const { control, formState: { errors, isSubmitted } } = useFormContext();

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const { t } = useTranslation();
    const [error, setError] = useState(false);
    useEffect(() => {

        if (tacTarget === "top") {
            setError(!checked);
            dispatch({
                type: types.setTerms,
                payload: checked
            });
        }
    }, [checked]);

    return (
        <Block>
            <p className="text-center">{t(`index.${tacTarget}Tac`)}</p>

            {tacTarget === "top" ? (
                <>
                    <FormGroup className="flex items-center justify-center mt-2">
                        <FormControlLabel
                            label={t("form.agree")}
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />} />
                    </FormGroup>
                    {error && loginState && <span className="text-[#d32f2f] text-xs block text-center w-full">{t("form.errors.required")}</span>}
                </>
            ) : (
                <Controller
                    name="terms"
                    control={control}
                    rules={{ required: t("index.form.required") }}
                    render={({ field }) => (
                        <>
                            <FormGroup className="flex items-center justify-center mt-2">
                                <FormControlLabel
                                    label={t("form.agree")}
                                    control={
                                        <Checkbox
                                            {...field}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    } />
                            </FormGroup>
                            {errors?.terms && isSubmitted && <span className="w-full block text-center text-[#d32f2f] text-xs">{t("form.errors.required")}</span>}
                        </>
                    )}
                />
            )}
        </Block>
    )
}

export default TermsAndConditions;