import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Block from "../../layout/Blocks";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
const TermsAndConditions = ({ tacTarget }) => {

    const [checked, setChecked] = useState(true);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const { t } = useTranslation();

    return (
        <Block>
            <p className="text-center">{t(`index.${tacTarget}Tac`)}</p>
            <FormGroup className="flex items-center justify-center mt-2">
                <FormControlLabel
                    label={t("form.agree")}
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} />
            </FormGroup>
        </Block>
    )
}

export default TermsAndConditions;