import { Checkbox, CircularProgress, FormControlLabel, FormGroup } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StoreContext } from "../../store/StoreProvider";

const ListCerts = ({ cert, index, mode, setActiveCert, active_cert }) => {

    const { t } = useTranslation();

    useEffect(() => {
        console.log('active_cert', active_cert);
    }, [active_cert]);

    const handleChange = (event) => {

        console.log('handleChange listCert');

        const state = event.target.checked;
        setActiveCert(state ? index : null);
    }
    const { firstname, lastname, assignament, from_date } = cert;
    const validity = new Date(from_date)
        .toISOString()
        .slice(0, 10)
        .split("-")
        .reverse()
        .join("/");

    return (
        <>
            {mode === "desktop" ? (
                <tr>
                    <td className="text-xs mt-2">
                        <span className="block">{assignament}</span>
                    </td>
                    <td className="text-xs">
                        <span className="block">{firstname} {lastname}</span>
                    </td>

                    <td className="text-left text-xs">
                        <span className="block">{validity}</span>
                    </td>

                    <td>
                        <FormGroup className="flex items-end justify-center">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={active_cert == index}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} />
                        </FormGroup>

                    </td>
                </tr>) : (
                <>
                    <tr>
                        <table id="table-content">
                            <tr>
                                <td className="text-xs" colspan="2">
                                    <span className="font-bold">{t("index.beneficiaryName")}</span>
                                    <span className="block">{firstname} {lastname}</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-xs">
                                    <span className="font-bold">{t("index.certNumber")}</span>
                                    <span className="block">{assignament}</span>
                                </td>
                                <td className="text-left text-xs">
                                    <div className="flex justify-between items-center">
                                        <div className="mr-4">
                                            <span className="font-bold">{t("index.validity")}</span>
                                            <span className="block">{validity}</span>
                                        </div>
                                        <FormGroup className="flex items-end justify-end">
                                            <Checkbox
                                                checked={active_cert == index}
                                                onChange={handleChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </FormGroup>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </tr>
                </>
            )
            }
        </>)
};

export default ListCerts;
