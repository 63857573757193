import React, { useEffect, useState } from "react";
import Block from "../../layout/Blocks";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import MediaQuery from "react-responsive";

const CertSelection = ({ breakpoint }) => {

    const { t } = useTranslation();

    const [checked, setChecked] = useState(true);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        console.log('breakpoint', breakpoint);
    }, [breakpoint]);

    return (
        <Block mode="clean">

            <MediaQuery minWidth={620}>
                <table id="cert-list-md" className="table-auto w-full border-collapse border-spacing-0">
                    <thead>
                        <tr>
                            <th className="text-xs">{t("index.certNumber")}</th>
                            <th className="text-xs">{t("index.beneficiaryName")}</th>
                            <th className="text-xs">{t("index.validity")}</th>
                            <th className="text-xs">{t("index.select")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-xs">
                                <span className="block">TEST TEST</span>
                            </td>
                            <td className="text-xs">
                                <span className="block">Juan Carlos de Las Morenas</span>
                            </td>

                            <td className="text-left text-xs">
                                <span className="block">TEST TEST</span>
                            </td>

                            <td>
                                <FormGroup className="flex items-center justify-center">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={handleChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />} />
                                </FormGroup>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-xs">
                                <span className="block">TEST TEST</span>
                            </td>
                            <td className="text-xs">
                                <span className="block">TEST TEST</span>
                            </td>

                            <td className="text-left text-xs">
                                <span className="block">TEST TEST</span>
                            </td>

                            <td>
                                <FormGroup className="flex items-center justify-center">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={handleChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />} />
                                </FormGroup>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </MediaQuery>
            <MediaQuery maxWidth={619}>
                <table id="cert-list" className="table-auto w-full">
                    <tr>
                        <td className="text-xs">
                            <span className="font-bold">{t("index.beneficiaryName")}</span>
                            <span className="block">TEST TEST</span>
                        </td>
                        <td>
                            <FormGroup className="flex items-end justify-center">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />} />
                            </FormGroup>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-xs">
                            <span className="font-bold">{t("index.certNumber")}</span>
                            <span className="block">TEST TEST</span>
                        </td>
                        <td className="text-left text-xs">
                            <span className="font-bold">{t("index.validity")}</span>
                            <span className="block">TEST TEST</span>
                        </td>
                    </tr>
                </table>
            </MediaQuery>
        </Block >
    )

}

export default CertSelection;