import { Box, Button, Modal, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';

const SuccessModal = ({ open, email, closeSuccessModal }) => {
    const { t } = useTranslation();
    const handleClose = () => {
        console.log('close')
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        textAlign: 'center',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: '440px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    let closeImg = { cursor: 'pointer', right: '0.5rem', top: '0.5rem', width: '20px', position: 'absolute' };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <CloseIcon style={closeImg} onClick={closeSuccessModal} />
                    <Typography variant="h6" component="h2" className="
                    text-blue-secondary text-center font-titillium text-xl font-semibold leading-6">
                        {t("successModal.title")}
                    </Typography>
                    <Typography sx={{ mt: 2, fontFamily: 'Inter' }}>
                        {t("successModal.content", { email: email })}
                    </Typography>

                    <Button
                        onClick={closeSuccessModal}
                        className="mt-4 max-w-[100px] font-sans font-medium" variant="contained" disableElevation fullWidth>
                        {t("successModal.close")}
                    </Button>
                </Box>
            </Modal>
        </>
    )
}

export default SuccessModal;