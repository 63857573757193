import { useTranslation } from 'react-i18next';
import Block from '../../layout/Blocks';
import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { es_currencies, pt_currencies } from './currencies';
import { Controller, useFormContext } from "react-hook-form"

const Payment = () => {

    const { t, i18n } = useTranslation();
    const [currencies, setCurrencies] = useState([]);
    const { control, formState: { errors, isSubmitted } } = useFormContext() // retrieve all hook methods

    useEffect(() => {
        if (i18n.language === 'pt-BR') {
            setCurrencies(pt_currencies);
        } else {
            setCurrencies(es_currencies);
        }
    }, [])

    const parsedCurrency = (value) => {
        if (value) {
            const regExp = /\(([^)]+)\)/;
            return regExp.exec(value)[1] || null;
        }
    }

    return (
        <Block title={t("index.payment.title")}>
            <div>
                <div className="mt-4 flex flex-col max-w-full space-y-1 sm:max-w-[65%] gap-4">
                    <div className="relative">
                        <label className="form-label">Moneda</label>

                        <Controller
                            control={control}
                            name="total_amount_currency"
                            rules={{ required: t("form.errors.required") }}
                            render={({ field }) => (

                                <Autocomplete
                                    disablePortal
                                    onChange={(event, value) => field.onChange(parsedCurrency(value))}
                                    hiddenLabel
                                    options={currencies}
                                    sx={{
                                        height: '40px!important',
                                        fontSize: '16px',
                                        fontFamily: 'Inter,sans-serif',
                                        fontWeight: '600',
                                        backgroundColor: 'white',
                                        color: '#526166',
                                    }}
                                    renderInput={(params) => <TextField
                                        error={errors?.total_amount_currency && isSubmitted}
                                        helperText={errors?.total_amount_currency?.message}
                                        hiddenLabel
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                padding: "2px 9px",
                                            }
                                        }}
                                        inputProps={{
                                            style: {
                                                padding: 0
                                            }
                                        }}
                                        {...params} />}
                                />
                            )} />
                    </div>

                    <div className="relative">
                        <label className="form-label">{t("index.payment.total")}</label>
                        <Controller
                            control={control}
                            name="total_amount"
                            rules={{ required: t("form.errors.required") }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="number"
                                    size="small"
                                    error={errors?.total_amount && isSubmitted}
                                    helperText={errors?.total_amount?.message}
                                    fullWidth
                                    hiddenLabel
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </Block>
    )

};

export default Payment;