const initialStore = {
  cert_data: null,
  active_cert: null,
  form: {
    reasons: {
      0: false,
      1: false,
      2: false,
      3: false,
    },
    feedback: null,
    submitted: false,
  },
  terms: false,
};

const types = {
  setCertData: "data/set",
  setActiveCert: "cert/set",
  setForm: "form/set",
  clearForm: "form/clear",
  setTerms: "terms/set",
};

const storeReducer = (state, action) => {
  switch (action.type) {
    case types.setCertData:
      return {
        ...state,
        cert_data: action.payload,
      };
    case types.setActiveCert:
      return {
        ...state,
        active_cert: action.payload,
      };
    case types.setForm:
      return {
        ...state,
        form: action.payload,
      };
    case types.setTerms:
      return {
        ...state,
        terms: action.payload,
      };
    case types.clearForm:
      return initialStore;
    default:
      return state;
  }
};

export { initialStore, types };
export default storeReducer;
