import React, { useContext, useEffect, useRef, useState } from 'react';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import { useTranslation } from 'react-i18next';
import refundsGirlComputer from "../../assets/img/refundsGirlComputer.png";
import Login from '../Login';
import CertSelection from '../CertSelection';
import Email from '../Email';
import ButtonSubmit from '../ButtonSubmit';
import PersonalData from '../PersonalData';
import Reasons from '../Reasons';
import Payment from '../Payment';
import Feedback from '../Feedback';
import PaymentMethod from '../PaymentMethod';
import SuccessModal from '../SuccessModal';
import { StoreContext } from '../../store/StoreProvider';
import { types } from '../../store/StoreReducer';
import { useForm, FormProvider } from "react-hook-form"
import { SendRefundPOSTController } from '../../dataController/dataController';
import { useLoader } from "../../context/loaderContext";

export function Refunds() {

    const { t } = useTranslation();
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [provider, setProvider] = useState(null);
    const [email, setEmail] = useState(null);
    const { setLoader } = useLoader();

    const [store, dispatch] = useContext(StoreContext)
    const { active_cert, form, terms } = store
    const [errorReasons, setErrorReasons] = useState(false);
    const [loginState, setLoginState] = useState(false);

    const reasonsRef = useRef(null);
    const tacRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const { ...methods } = useForm({
        mode: 'onChange',
        defaultValues: {
            fullname: '',
            certified_number: '',
            email: '',
            lang: 'es',
            country_of_residence: '164',
            provider: '',
            terms: false,
        }
    }
    );

    const modalSuccess = (open, provider) => {
        setOpenSuccessModal(open);
    };

    const onSubmit = (data) => {

        if (form?.files || methods.getValues('provider') && terms) {

            setProvider(methods.getValues('provider'));
            setEmail(methods.getValues('email'));

            setLoader(true);

            const fetchData = async () => {
                try {
                    dispatch({
                        type: types.clearForm,
                        payload: null
                    });

                    //? process uploaded files

                    if (form?.files) {
                        form.files.map((files) => {
                            const { name, file } = files;
                            data[`${name}`] = file;
                        }
                        );
                    }

                    //? clean empty data, terms flag and provider
                    if (data.comments === '' || !data.comments) {
                        delete data.comments;
                    }

                    if (data.hasOwnProperty('terms')) {
                        delete data.terms;
                    }

                    if (data?.hasOwnProperty('provider')) {
                        delete data.provider
                    }

                    window.scrollTo({ top: 0, behavior: 'instant' });

                    const result = await SendRefundPOSTController.fetchData({ body: data });
                    const resp = result.data;

                    if (resp.status) {
                        methods.reset();
                        dispatch({
                            type: types.setTerms,
                            payload: true
                        });

                        modalSuccess(true, provider);
                    }

                } catch (error) {
                } finally {
                    setLoader(false);
                }
            };
            fetchData();
        } else {
            if (terms === false) {
                tacRef.current?.scrollIntoView({
                    behavior: 'smooth'
                });
                return;
            }

            if (!form?.files) {
                reasonsRef.current?.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }

    const closeSuccessModal = () => {
        setOpenSuccessModal(false);
    }

    const submitState = (state) => {
        setLoginState(state);
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <SuccessModal open={openSuccessModal} provider={provider} closeSuccessModal={closeSuccessModal} email={email} />
                    <div className="py-16 px-4 flex flex-col items-center">
                        <h1 className="font-titillium text-3xl md:text-3xl xl:text-4xl text-[#2F6DE0] text-center font-bold pb-12 px-1">
                            {t("index.title")}
                        </h1>
                        <img className="w-[270px] pb-12" src={refundsGirlComputer} />
                        <div className="max-w-[270px] sm:max-w-[852px] text-base md:text-2xl font-medium text-center font-titillium mb-8">
                            <h2>{t("index.subTitle")}</h2>
                            <h2>{t("index.subTitle2")}</h2>
                        </div>
                        <div ref={tacRef}>
                            <TermsAndConditions loginState={loginState} tacTarget="top" />
                        </div>
                        <Login submitState={submitState} />
                        {<CertSelection active_cert={active_cert} />}
                        {methods.getValues('provider') == 2 && <Email />}
                        {active_cert && (
                            <>
                                {methods.getValues('provider') != 2 &&
                                    (
                                        <>
                                            <PersonalData />
                                            <div className="w-full mx-auto" ref={reasonsRef}>
                                                <Reasons errorReasons={errorReasons} />
                                            </div>
                                            <Payment />
                                            <Feedback />
                                            <PaymentMethod />
                                            <TermsAndConditions tacTarget="bottom" />
                                        </>)
                                }
                                <ButtonSubmit onSubmit={methods.handleSubmit(onSubmit)} />
                            </>
                        )}
                    </div>
                </form>
            </FormProvider >
        </>
    );
}