import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translations: {
        index: {
          title: "Preencha este formulário",
          subTitle: "Puedes procesarlo aquí, de forma rápida, simple y online.",
          subTitle2:
            "Hasta 30  días corridos desde la fecha de vencimiento de tu Certificado de Asistencia.",
          topTac:
            "Ao retornar de sua viagem, você poderá apresentar seu reembolso no prazo máximo de 30 dias corridos a partir da data de vencimento de sua apólice.",
          bottomTac:
            "Os reembolsos da ASSIST 365 são tratados por uma equipe especializada. Para avaliar o seu pedido é essencial que nos forneça toda a informação completa. Podemos precisar fornecer os dados e relatórios para outras pessoas como: especialistas, profissionais de saúde ou outros profissionais de que precisamos para essa tarefa. Sua privacidade será sempre protegida.",
          enterDocPass: "Número do passaporte / Identidade",
          enterEmail: "Ingresa tu correo electrónico",
          chooseCert: "Selecione a apólice que necessita solicitar reembolso",
          beneficiaryName: "Nome do benificiário",
          validity: "Datas de validade",
          certNumber: "Número da apólice",
          certNumberFull: "Número de certificado de asistencia",
          fullname: "Nome e sobrenome",
          email: "E-mail",
          select: "Selecione",
          reasonTitle: "Motivo da solicitação de reembolso",
          reasons: {
            1: "Assistência médica",
            2: "Odontologia",
            3: "Medicamentos",
            4: "Outros",
          },
        },
        form: {
          send: "Mandar",
          agree: "De acordo",
          sendRequest: "Enviar solicitação",
        },
      },
    },
    es: {
      translations: {
        index: {
          title: "Solicitar reintegro",
          subTitle: "Puedes procesarlo aquí, de forma rápida, simple y online.",
          subTitle2:
            "Hasta 30  días corridos desde la fecha de vencimiento de tu Certificado de Asistencia.",
          topTac:
            "Al regresar de tu viaje, podrás presentar tu reintegro en un tiempo máximo de 30 días corridos desde la fecha de vencimiento de tu Certificado de Asistencia.",
          bottomTac:
            "Los reintegros de ASSIST 365 son trabajados por un equipo especialista. Para evaluar tu solicitud es imprescindible que nos facilites toda la información completa. Es posible que debamos proporcionar datos e informes a otras personas, como peritos, profesionales de la salud, u otros profesionales que necesitamos para esta tarea. Siempre tu privacidad estará cuidada al máximo.",
          enterDocPass: "Ingresá número de documento o pasaporte",
          enterEmail: "Ingresa tu correo electrónico",
          chooseCert:
            "Selecciona el Certificado por el cual necesitas pedir tu reintegro",
          beneficiaryName: "Nombre del beneficiario",
          validity: "Fechas de vigencia",
          certNumber: "Nro. de certificado",
          fullname: "Nombre y apellido",
          certNumberFull: "Número de certificado de asistencia",
          email: "E-mail",
          select: "Seleccionar",
          payment: {
            title: "Monto total a reintegrar",
            currency: "Moneda",
            total: "Valor total",
          },
          paymentMethod: {
            title: "Medio de pago",
            content:
              "Luego de analizada la solicitud de reintegro, en caso de que sea aprobada, te solicitaremos los <strong>datos bancarios</strong> vía mail para avanzar con el pago.",
          },
          feedback: {
            title: "¿Quieres contarnos algo más sobre lo sucedido?",
            placeholder: "Puedes añadir aquí comentarios sobre lo ocurrido",
          },
          reasonTitle: "Motivo de solicitud de reintegros",
          reasons: {
            1: "Asistencia Médica",
            2: "Odontología",
            3: "Medicamentos",
            4: "Otros",
          },
          reasonsData: {
            subtitle: "Adjuntar documentación respaldatoria",
            uploadRules: {
              1: "Certificado emitido por el médico tratante (con detalle del tratamiento recibido y diagnóstico médico)",
              2: "Factura y comprobantes de pago por honorarios médicos",
              3: "Foto de pasaporte o documento de identidad",
            },
            selectFiles: "Seleccionar archivos",
            selectOrDrag: "Selecciona o arrastra aquí",
            dragHere: "Suelta aquí",
            update: "Actualizar",
            delete: "Eliminar",
          },
        },
        form: {
          send: "Enviar",
          sendRequest: "Enviar solicitud",
          agree: "Estoy de acuerdo",
        },
        successModal: {
          title: "Registramos con éxito tu solicitud",
          content:
            "Te estaremos enviando a la brevedad a {{email}} las instrucciones para que continúes con la gestión",
          close: "Cerrar",
        },
      },
    },
    "es-AR": {
      translations: {
        index: {
          title: "Solicitar reintegro",
          subTitle: "Puedes procesarlo aquí, de forma rápida, simple y online.",
          subTitle2:
            "Hasta 30  días corridos desde la fecha de vencimiento de tu Certificado de Asistencia.",
          topTac:
            "Al regresar de tu viaje, podrás presentar tu reintegro en un tiempo máximo de 30 días corridos desde la fecha de vencimiento de tu Certificado de Asistencia.",
          bottomTac:
            "Los reintegros de ASSIST 365 son trabajados por un equipo especialista. Para evaluar tu solicitud es imprescindible que nos facilites toda la información completa. Es posible que debamos proporcionar datos e informes a otras personas, como peritos, profesionales de la salud, u otros profesionales que necesitamos para esta tarea. Siempre tu privacidad estará cuidada al máximo.",
          enterDocPass: "Ingresá número de documento o pasaporte",
          enterEmail: "Ingresa tu correo electrónico",
          chooseCert:
            "Selecciona el Certificado por el cual necesitas pedir tu reintegro",
          beneficiaryName: "Nombre del beneficiario",
          validity: "Fechas de vigencia",
          certNumber: "Nro. de certificado",
          fullname: "Nombre y apellido",
          certNumberFull: "Número de certificado de asistencia",
          email: "E-mail",
          select: "Seleccionar",
          payment: {
            title: "Monto total a reintegrar",
            currency: "Moneda",
            total: "Valor total",
          },
          paymentMethod: {
            title: "Medio de pago",
            content:
              "Luego de analizada la solicitud de reintegro, en caso de que sea aprobada, te solicitaremos los <strong>datos bancarios</strong> vía mail para avanzar con el pago.",
          },
          feedback: {
            title: "¿Quieres contarnos algo más sobre lo sucedido?",
            placeholder: "Puedes añadir aquí comentarios sobre lo ocurrido",
          },
          reasonTitle: "Motivo de solicitud de reintegros",
          reasons: {
            1: "Asistencia Médica",
            2: "Odontología",
            3: "Medicamentos",
            4: "Otros",
          },
          reasonsData: {
            subtitle: "Adjuntar documentación respaldatoria",
            uploadRules: {
              1: "Certificado emitido por el médico tratante (con detalle del tratamiento recibido y diagnóstico médico)",
              2: "Factura y comprobantes de pago por honorarios médicos",
              3: "Foto de pasaporte o documento de identidad",
            },
            selectFiles: "Seleccionar archivos",
            selectOrDrag: "Selecciona o arrastra aquí",
            dragHere: "Suelta aquí",
            update: "Actualizar",
            delete: "Eliminar",
          },
        },
        form: {
          send: "Enviar",
          sendRequest: "Enviar solicitud",
          agree: "Estoy de acuerdo",
        },
        successModal: {
          title: "Registramos con éxito tu solicitud",
          content:
            "Te estaremos enviando a la brevedad a {{email}} las instrucciones para que continúes con la gestión",
          close: "Cerrar",
        },
      },
    },
  },
  lng: "es-AR",
  fallbackLng: "pt-BR",
  ns: ["translations"],
  defaultNS: "translations",
});
export default i18n;
