import { Trans, useTranslation } from "react-i18next"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Block from "../../layout/Blocks";

const PaymentMethod = () => {

    const { t } = useTranslation();
    return (
        <Block>
            <div className="-mt-[10px]">
                <Accordion elevation={0} sx={{ '&:before': { height: '0px' } }} disableGutters>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        sx={{
                            padding: "0 0.25rem", margin: 0, border: 0,
                            "& .MuiAccordionSummary-content": {
                                margin: "0"
                            }
                        }}
                    ><h2 className="leading-tight text-lg text-blue-secondary font-semibold font-titillium">{t("index.paymentMethod.title")}</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                        <Trans i18nKey="index.paymentMethod.content" />
                        </p>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Block>
    )
}

export default PaymentMethod;