import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';

const ButtonSubmit = ({ onSubmit}) => {

    const { t } = useTranslation();

    return (
        <Button onClick={onSubmit} className="btn-core ml-0 my-9 p-4 max-w-80 font-sans capitalize text-xl font-semibold" variant="contained" disableElevation fullWidth>
            {t("form.sendRequest")}
        </Button>
    )
}

export default ButtonSubmit;