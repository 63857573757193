export const es_currencies = [
  "Dirham de los Emiratos Árabes Unidos (AED)",
  "Afgani afgano (AFN)",
  "Lek albanés (ALL)",
  "Dram armenio (AMD)",
  "Kwanza angoleño (AOA)",
  "Peso argentino (ARS)",
  "Dólar australiano (AUD)",
  "Manat azerí (AZN)",
  "Marco convertible (BAM)",
  "Dólar de Barbados (BBD)",
  "Taka bangladeshí (BDT)",
  "Lev búlgaro (BGN)",
  "Dinar bahreiní (BHD)",
  "Franco de Burundi (BIF)",
  "Dólar de Brunéi (BND)",
  "Boliviano (BOB)",
  "Real brasileño (BRL)",
  "Dólar bahameño (BSD)",
  "Ngultrum butanés (BTN)",
  "Pula (BWP)",
  "Rublo bielorruso (BYN)",
  "Dólar beliceño (BZD)",
  "Dólar canadiense (CAD)",
  "Franco congoleño (CDF)",
  "Franco suizo (CHF)",
  "Peso chileno (CLP)",
  "Peso colombiano (COP)",
  "Colón costarricense (CRC)",
  "Peso cubano (CUP)",
  "Escudo caboverdiano (CVE)",
  "Corona checa (CZK)",
  "Franco yibutiano (DJF)",
  "Corona danesa (DKK)",
  "Peso dominicano (DOP)",
  "Dinar argelino (DZD)",
  "Libra egipcia (EGP)",
  "Nakfa (ERN)",
  "Birr etíope (ETB)",
  "Euro (EUR)",
  "Dólar fiyiano (FJD)",
  "Libra británica (GBP)",
  "Lari georgiano (GEL)",
  "Cedi (GHS)",
  "Dalasi (GMD)",
  "Franco guineano (GNF)",
  "Quetzal guatemalteco (GTQ)",
  "Dólar guyanés (GYD)",
  "Lempira hondureño (HNL)",
  "Kuna croata (HRK)",
  "Gourde haitiano (HTG)",
  "Forinto húngaro (HUF)",
  "Rupia indonesia (IDR)",
  "Nuevo shéquel (ILS)",
  "Rupia india (INR)",
  "Dinar iraquí (IQD)",
  "Rial iraní (IRR)",
  "Corona islandes (ISK)",
  "Dólar jamaiquino (JMD)",
  "Dinar jordano (JOD)",
  "Yen (JPY)",
  "Chelín keniano (KES)",
  "Som kirguís (KGS)",
  "Riel camboyano (KHR)",
  "Franco comorano (KMF)",
  "Won norcoreano (KPW)",
  "Won surcoreano (KPW)",
  "Dinar kuwaití (KWD)",
  "Tenge kazajo (KZT)",
  "Kip laosiano (LAK)",
  "Libra libanesa (LBP)",
  "Rupia de Sri Lanka (LKR)",
  "Dólar liberiano (LRD)",
  "Loti (LSL)",
  "Dinar libio (LYD)",
  "Dirham marroquí (MAD)",
  "Leu moldavo (MDL)",
  "Ariary malgache (MGA)",
  "Denar macedonio (MKD)",
  "Kyat birmano (MMK)",
  "Tugrik mongol (MNT)",
  "Uguiya (MRO)",
  "Rupia de Mauricio (MUR)",
  "Rupia de Maldivas (MVR)",
  "Kwacha malauí (MWK)",
  "Peso mexicano (MXN)",
  "Ringgit malayo (MYR)",
  "Metical mozambiqueño (MZN)",
  "Dólar namibio (NAD)",
  "Naira (NGN)",
  "Córdoba nicaragüense (NIO)",
  "Corona noruega (NOK)",
  "Rupia nepalí (NPR)",
  "Dólar neozelandés (NZD)",
  "Rial omaní (OMR)",
  "Balboa panameño (PAB)",
  "Nuevo sol (PEN)",
  "Kina (PGK)",
  "Peso filipino (PHP)",
  "Rupia pakistaní (PKR)",
  "Zloty (PLN)",
  "Guaraní (PYG)",
  "Riyal qatarí (QAR)",
  "Leu rumano (RON)",
  "Dinar serbio (RSD)",
  "Rublo ruso (RUB)",
  "Franco ruandés (RWF)",
  "Riyal saudí (SAR)",
  "Dólar de las Islas Salomón (SBD)",
  "Rupia de Seychelles (SCR)",
  "Libra sudanesa (SDG)",
  "Corona sueca (SEK)",
  "Dólar de Singapur (SGD)",
  "Leone (SLL)",
  "Chelín somalí (SOS)",
  "Dólar surinamés (SRD)",
  "Libra sursudanesa (SSP)",
  "Dobra (STD)",
  "Libra siria (SYP)",
  "Lilangeni (SZL)",
  "Baht tailandés (THB)",
  "Somoni tayiko (TJS)",
  "Manat turcomano (TMT)",
  "Dinar tunecino (TND)",
  "Pa'anga (TOP)",
  "Lira turca (TRY)",
  "Dólar trinitense (TTD)",
  "Nuevo dólar taiwanés (TWD)",
  "Chelín tanzano (TZS)",
  "Grivna (UAH)",
  "Chelín ugandés (UGX)",
  "Dólar estadounidense (USD)",
  "Peso uruguayo (UYU)",
  "Som uzbeko (UZS)",
  "Bolívar fuerte (VEF)",
  "Dong vietnamita (VND)",
  "Vatu (VUV)",
  "Tala (WST)",
  "Franco CFA de África Central (XAF)",
  "Dólar del Caribe Oriental (XCD)",
  "Franco CFA de África Occidental (XOF)",
  "Rial yemení (YER)",
  "Rand sudafricano (ZAR)",
  "Kwacha zambiano (ZMW)",
];

export const pt_currencies = [
  "Dirham dos Emirados Árabes Unidos (AED)",
  "Afegane afegão (AFN)",
  "Lek albanês (ALL)",
  "Dram armênio (AMD)",
  "Kwanza angolano (AOA)",
  "Peso argentino (ARS)",
  "Dólar australiano (AUD)",
  "Manat azerbaijano (AZN)",
  "Marco conversível (BAM)",
  "Dólar de Barbados (BBD)",
  "Taka de Bangladesh (BDT)",
  "Lev búlgaro (BGN)",
  "Dinar do Bahrein (BHD)",
  "Franco burundiano (BIF)",
  "Dólar de Brunei (BND)",
  "Boliviano (BOB)",
  "Real brasileiro (BRL)",
  "Dólar das Bahamas (BSD)",
  "Ngultrum butanês (BTN)",
  "Pula (BWP)",
  "Rublo bielorrusso (BYN)",
  "Dólar belizenho (BZD)",
  "Dólar canadense (CAD)",
  "Franco congolês (CDF)",
  "Franco suíço (CHF)",
  "Peso chileno (CLP)",
  "Peso colombiano (COP)",
  "Colón costa-riquenho (CRC)",
  "Peso cubano (CUP)",
  "Escudo cabo-verdiano (CVE)",
  "Coroa checa (CZK)",
  "Franco djiboutiano (DJF)",
  "Coroa dinamarquesa (DKK)",
  "Peso dominicano (DOP)",
  "Dinar argelino (DZD)",
  "Libra egípcia (EGP)",
  "Nakfa (ERN)",
  "Birr etíope (ETB)",
  "Euro (EUR)",
  "Dólar de Fiji (FJD)",
  "Libra esterlina (GBP)",
  "Lari georgiano (GEL)",
  "Cedi (GHS)",
  "Dalasi (GMD)",
  "Franco guineano (GNF)",
  "Quetzal guatemalteco (GTQ)",
  "Dólar da Guiana (GYD)",
  "Lempira hondurenha (HNL)",
  "Kuna croata (HRK)",
  "Gourde haitiano (HTG)",
  "Forint húngaro (HUF)",
  "Rupia indonésia (IDR)",
  "Novo shekel (ILS)",
  "Rupia indiana (INR)",
  "Dinar iraquiano (IQD)",
  "Rial iraniano (IRR)",
  "Coroa islandesa (ISK)",
  "Dólar jamaicano (JMD)",
  "Dinar jordaniano (JOD)",
  "Iene (JPY)",
  "Xelim queniano (KES)",
  "Riel cambojano (KHR)",
  "Franco comoriano (KMF)",
  "Won norte-coreano (KPW)",
  "Won sul-coreano (KRW)",
  "Dinar kuwaitiano (KWD)",
  "Tenge cazaque (KZT)",
  "Kip laosiano (LAK)",
  "Libra libanesa (LBP)",
  "Rupia do Sri Lanka (LKR)",
  "Dólar liberiano (LRD)",
  "Loti (LSL)",
  "Dinar líbio (LYD)",
  "Dirham marroquino (MAD)",
  "Leu moldavo (MDL)",
  "Ariary malgaxe (MGA)",
  "Denar macedônio (MKD)",
  "Kyat birmanês (MMK)",
  "Tugrik mongol (MNT)",
  "Ouguiya (MRO)",
  "Rupia de Maurício (MUR)",
  "Rupia das Maldivas (MVR)",
  "Kwacha do Malawi (MWK)",
  "Peso mexicano (MXN)",
  "Ringgit malaio (MYR)",
  "Metical moçambicano (MZN)",
  "Dólar namibiano (NAD)",
  "Naira (NGN)",
  "Córdoba nicaraguense (NIO)",
  "Coroa norueguesa (NOK)",
  "Rupia nepalesa (NPR)",
  "Dólar neozelandês (NZD)",
  "Rial omanense (OMR)",
  "Balboa panamenho (PAB)",
  "Nuevo sol (PEN)",
  "Kina (PGK)",
  "Peso filipino (PHP)",
  "Rupia paquistanesa (PKR)",
  "Zloty (PLN)",
  "Guarani (PYG)",
  "Riyal catariano (QAR)",
  "Leu romeno (RON)",
  "Dinar sérvio (RSD)",
  "Rublo russo (RUB)",
  "Franco ruandês (RWF)",
  "Riyal saudita (SAR)",
  "Dólar das Ilhas Salomão (SBD)",
  "Rupia de Seychelles (SCR)",
  "Libra sudanesa (SDG)",
  "Coroa sueca (SEK)",
  "Dólar de Singapura (SGD)",
  "Leone (SLL)",
  "Xelim somali (SOS)",
  "Dólar surinamês (SRD)",
  "Libra sul-sudanesa (SSP)",
  "Dobra (STD)",
  "Libra síria (SYP)",
  "Lilangeni (SZL)",
  "Baht tailandês (THB)",
  "Somoni tadjique (TJS)",
  "Manat turcomeno (TMT)",
  "Dinar tunisiano (TND)",
  "Pa'anga (TOP)",
  "Lira turca (TRY)",
  "Dólar de Trinidad e Tobago (TTD)",
  "Novo dólar taiwanês (TWD)",
  "Xelim tanzaniano (TZS)",
  "Hryvnia (UAH)",
  "Xelim ugandense (UGX)",
  "Dólar americano (USD)",
  "Peso uruguaio (UYU)",
  "Som uzbeque (UZS)",
  "Bolívar forte (VEF)",
  "Dong vietnamita (VND)",
  "Vatu (VUV)",
  "Tala (WST)",
  "Franco CFA da África Central (XAF)",
  "Dólar do Caribe Oriental (XCD)",
  "Franco CFA da África Ocidental (XOF)",
  "Rial iemenita (YER)",
  "Rand sul-africano (ZAR)",
  "Kwacha zambiano (ZMW)",
];
