import { useTranslation } from "react-i18next";
import Block from "../../layout/Blocks";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const Feedback = () => {

    const { t } = useTranslation();
    const { control } = useFormContext();

    return (
        <Block title={t("index.feedback.title")}>
            <div className="flex flex-col max-w-full space-y-1 sm:max-w-[65%] gap-4">

                <Controller
                    control={control}
                    name="comments"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            placeholder={t("index.feedback.placeholder")}
                            multiline
                            rows={4}
                        />
                    )}

                />

            </div>
        </Block>
    )

}

export default Feedback;