import { useTranslation } from "react-i18next";
import Block from "../../layout/Blocks";
import { TextField } from "@mui/material";

const Feedback = () => {

    const { t } = useTranslation();

    return (
        <Block title={t("index.feedback.title")}>
            <div className="flex flex-col max-w-full space-y-1 sm:max-w-[65%] gap-4">
                <TextField
                    fullWidth
                    placeholder={t("index.feedback.placeholder")}
                    multiline
                    rows={4}
                    maxRows={4}
                />
            </div>
        </Block>
    )

}

export default Feedback;