import { useTranslation } from 'react-i18next';
import Block from '../../layout/Blocks';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { es_currencies, pt_currencies } from './currencies';

const Payment = () => {

    const { t, i18n } = useTranslation();

    console.log(i18n.language);

    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        if (i18n.language === 'pt-BR') {
            setCurrencies(pt_currencies);
        } else {
            setCurrencies(es_currencies);
        }
    }, [])


    const [age, setAge] = useState('');

    const handleChange = (e) => {
        setAge(e.target.value);
    };


    return (
        <Block title={t("index.payment.title")}>
            <div>
                <div className="mt-4 flex flex-col max-w-full space-y-1 sm:max-w-[65%] gap-4">
                    <FormControl size="small">
                        <label className="form-label">Moneda</label>

                        <Autocomplete
                            disablePortal
                            hiddenLabel
                            options={currencies}
                            sx={{
                                height: '40px!important',
                                fontSize: '16px',
                                fontFamily: 'Inter,sans-serif',
                                fontWeight: '600',
                                backgroundColor: 'white',
                                color: '#526166',
                            }}
                            renderInput={(params) => <TextField
                                hiddenLabel
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        padding: "2px 9px",
                                    }
                                }}
                                inputProps={{
                                    style: {
                                        padding: 0
                                    }
                                }}
                                {...params} />}
                        />
                    </FormControl>

                    <FormControl>
                        <label className="form-label">{t("index.payment.total")}</label>
                        <TextField
                            size="small"
                            fullWidth
                            hiddenLabel
                            variant="outlined"
                        />
                    </FormControl>
                </div>
            </div>
        </Block>
    )

};

export default Payment;