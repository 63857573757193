import React, { useContext, useEffect, useState } from "react";
import Block from "../../layout/Blocks";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";
import { StoreContext } from "../../store/StoreProvider";
import ListCerts from "./listCerts";
import { types } from "../../store/StoreReducer";
import { useFormContext } from "react-hook-form";

const CertSelection = ({ active_cert }) => {

    const { t } = useTranslation();
    const [store, dispatch] = useContext(StoreContext)
    const { cert_data } = store;
    const { register, control, setValue, getValue, reset } = useFormContext() // retrieve all hook methods
    const [certList, setCertList] = useState([]);

    useEffect(() => {
        setCertList(cert_data?.data?.cert_list);
    }, [store]);

    useEffect(() => {
        setCertList([]);
        reset();
    }, []);

    const [checked, setChecked] = useState(true);

    const setActiveCert = (index) => {
        const val = index !== null ? index.toString() : null;
        dispatch({
            type: types.setActiveCert,
            payload: val
        });

        if (val) {
            const cert = cert_data?.data?.cert_list[index];
            setValue('fullname', `${cert?.firstname} ${cert?.lastname}`);
            setValue('certified_number', cert?.assignament);
            setValue('email', cert_data?.data?.pax_email);
            setValue('country_of_residence', cert?.country_of_residence);
            setValue('provider', cert?.provider);

            const lang = localStorage.getItem("lang") || 'es';
            setValue('lang', lang);

            if (cert?.provider != '2') {
                setValue('passport_dni', cert_data?.data?.passport_dni);
                setValue('pax_phone', cert_data?.data?.pax_phone);
            }

        } else {
            setCertList([]);
            reset();
        }
    }

    return (
        <Block mode="clean">
            <MediaQuery minWidth={620}>
                {certList && <table id="cert-list-md" className="table-auto w-full border-collapse border-spacing-0">
                    <thead>
                        <tr>
                            <th className="text-xs">{t("index.certNumber")}</th>
                            <th className="text-xs">{t("index.beneficiaryName")}</th>
                            <th className="text-xs">{t("index.validity")}</th>
                            <th className="text-xs">{t("index.select")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {certList && certList.map(function (object, i) {
                            return <ListCerts mode="desktop" active_cert={active_cert} setActiveCert={setActiveCert} index={i} cert={object} key={i} total={certList.length} />;
                        })}
                    </tbody>
                </table>}
            </MediaQuery>
            <MediaQuery maxWidth={619}>
                <table id="cert-list" className="table-auto w-full">
                    {certList && certList.map(function (object, i) {
                        return <ListCerts active_cert={active_cert} setActiveCert={setActiveCert} index={i} mode="mobile" cert={object} key={i} />;
                    })}
                </table>
            </MediaQuery>
        </Block >
    )

}

export default CertSelection;