import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translations: {
        index: {
          title: "Preencha este formulário",
          subTitle:
            "Solicite aqui seu reembolso de forma rápida, simples e online",
          subTitle2:
            "Reembolso em até 30 dias após recebermos toda a documentação necessária.",
          topTac:
            "Ao retornar de sua viagem, você poderá apresentar seu reembolso no prazo máximo de 30 dias corridos a partir da data de vencimento de sua apólice.",
          bottomTac:
            "Os reembolsos da ASSIST 365 são tratados por uma equipe especializada. Para avaliar o seu pedido é essencial que nos forneça toda a informação completa. Podemos precisar fornecer os dados e relatórios para outras pessoas como: especialistas, profissionais de saúde ou outros profissionais de que precisamos para essa tarefa. Sua privacidade será sempre protegida.",
          enterDocPass: "Número do passaporte / Identidade",
          enterEmail: "Digite seu endereço de e-mail",
          chooseCert: "Selecione a apólice que necessita solicitar reembolso",
          beneficiaryName: "Nome do benificiário",
          validity: "Datas de validade",
          certNumber: "Número da apólice",
          certNumberFull: "Número da apólice",
          payment: {
            title: "Monto total a reintegrar",
            currency: "Moeda",
            total: "Valor total",
          },
          paymentMethod: {
            title: "Meio de pagamento",
            content:
              "Após analisarmos a solicitação de reembolso, caso seja aprovada, solicitaremos os <strong>dados de pagamento</strong> via e-mail para avançar com o pagamento.",
          },
          feedback: {
            title: "Quer nos contar mais detalhes sobre o ocorrido?",
            placeholder: "Escreva aqui detalhes sobre o ocorrido",
          },
          fullname: "Nome e sobrenome",
          email: "E-mail",
          select: "Selecione",
          reasonTitle: "Motivo da solicitação de reembolso",
          reasons: {
            0: "Assistência médica",
            1: "Odontologia",
            2: "Medicamentos",
            3: "Outros",
          },
          reasonsData: {
            subtitle: "Anexar documentação comprobatória",
            uploadRules: {
              1: "Atestado emitido pelo dentista do atendimento (com detalhes do tratamento recebido, diagnóstico clínico,carimbo e assinatura)",
              2: "Nota fiscal e comprovante de pagamento de honorários do dentista",
              3: "Foto do passaporte ou documento de identidade",
            },
            filesRules: {
              maxFileSize: "Tamanho máximo: {{maxFileSize}} MBs",
              maxNumber: "Limite: {{maxNumber}} arquivos",
              acceptType: "Tipos permitidos: {{acceptType}}.",
            },
            selectFiles: "Seleccionar arquivos",
            selectOrDrag: "Selecione ou arraste aqui",
            dragHere: "Suelta aquí",
            update: "Actualizar",
            delete: "Eliminar",
          },
        },
        form: {
          send: "Mandar",
          sending: "Enviando...",
          agree: "De acordo",
          sendRequest: "Enviar solicitação",
          errors: {
            required: "Campo obrigatório",
            upload: "Por favor, carregue pelo menos um arquivo",
            certNotFound:
              "Verifica que el documento ingresado coincida con el que figura en el certificado de asistencia que aplica al reintegro que está solicitando.",
          },
        },
        successModal: {
          title: "Registramos con éxito tu solicitud",
          contentProviderOther:
            "O processo de avaliação tem um prazo máximo de 30 dias.<br/><br/>Qualquer dúvida ou consulta, você pode entrar em contato através do e-mail<a href='mailto: reintegros@assist-365.com'>reintegros@assist-365.com</a>.<br/><br/>Equipe Assist 365.",
          contentProviderWta:
            "Em breve, estaremos enviando para {{email}} as instruções para que você continue com o processo.<br/><br/> Qualquer dúvida ou consulta, você pode entrar em contato pelo e-mail <a href='mailto: reintegros@assist-365.com'>reintegros@assist-365.com</a>.<br/><br/>Equipe Assist 365 ",
          close: "Cerrar",
        },
      },
    },
    es: {
      translations: {
        index: {
          title: "Solicitar reintegro",
          subTitle: "Puedes procesarlo aquí, de forma rápida, simple y online.",
          subTitle2:
            "Hasta 30 días corridos desde la fecha de vencimiento de tu Certificado de Asistencia.",
          topTac:
            "Al regresar de tu viaje, podrás presentar tu reintegro en un tiempo máximo de 30 días corridos desde la fecha de vencimiento de tu Certificado de Asistencia.",
          bottomTac:
            "Los reintegros de ASSIST 365 son trabajados por un equipo especialista. Para evaluar tu solicitud es imprescindible que nos facilites toda la información completa. Es posible que debamos proporcionar datos e informes a otras personas, como peritos, profesionales de la salud, u otros profesionales que necesitamos para esta tarea. Siempre tu privacidad estará cuidada al máximo.",
          enterDocPass: "Ingresá número de documento o pasaporte",
          enterEmail: "Ingresa tu correo electrónico",
          chooseCert:
            "Selecciona el Certificado por el cual necesitas pedir tu reintegro",
          beneficiaryName: "Nombre del beneficiario",
          validity: "Fechas de vigencia",
          certNumber: "Nro. de certificado",
          fullname: "Nombre y apellido",
          certNumberFull: "Número de certificado de asistencia",
          email: "E-mail",
          select: "Seleccionar",
          payment: {
            title: "Monto total a reintegrar",
            currency: "Moneda",
            total: "Valor total",
          },
          paymentMethod: {
            title: "Medio de pago",
            content:
              "Luego de analizada la solicitud de reintegro, en caso de que sea aprobada, te solicitaremos los <strong>datos bancarios</strong> vía mail para avanzar con el pago.",
          },
          feedback: {
            title: "¿Quieres contarnos algo más sobre lo sucedido?",
            placeholder: "Puedes añadir aquí comentarios sobre lo ocurrido",
          },
          reasonTitle: "Motivo de solicitud de reintegros",
          reasons: {
            0: "Asistencia Médica",
            1: "Odontología",
            2: "Medicamentos",
            3: "Otros",
          },
          reasonsData: {
            subtitle: "Adjuntar documentación respaldatoria",
            uploadRules: {
              1: "Certificado emitido por el médico tratante (con detalle del tratamiento recibido y diagnóstico médico)",
              2: "Factura y comprobantes de pago por honorarios médicos",
              3: "Foto de pasaporte o documento de identidad",
            },
            filesRules: {
              maxFileSize: "Tamaño máximo: {{maxFileSize}} MB(s)",
              maxNumber: "Maximo de {{maxNumber}} archivos",
              acceptType: "Tipos permitidos: {{acceptType}}.",
            },
            selectFiles: "Seleccionar archivos",
            selectOrDrag: "Selecciona o arrastra aquí",
            dragHere: "Suelta aquí",
            update: "Actualizar",
            delete: "Eliminar",
          },
        },
        form: {
          send: "Enviar",
          sending: "Enviando...",
          sendRequest: "Enviar solicitud",
          agree: "Estoy de acuerdo",
          errors: {
            required: "Campo obligatorio",
            upload: "Debes subir al menos un archivo",
            certNotFound:
              "Verifica que el documento ingresado coincida con el que figura en el certificado de asistencia que aplica al reintegro que está solicitando.",
          },
        },
        successModal: {
          title: "Registramos con éxito tu solicitud",
          contentProviderOther:
            "El proceso de evaluación consta de un máximo de 30 días.<br/><br/>Cualquier duda o consulta puedes comunicarte a <a href='mailto: reintegros@assist-365.com'>reintegros@assist-365.com</a>.<br/><br/>Equipo Assist 365.",
          contentProviderWta:
            "Te estaremos enviando a la brevedad a {{email}} las instrucciones para que continúes con la gestión.<br/><br/> Cualquier duda o consulta puedes comunicarte a <a href='mailto: reintegros@assist-365.com'>reintegros@assist-365.com</a>.<br/><br/>Equipo Assist 365 ",
          close: "Cerrar",
        },
      },
    },
    "es-AR": {
      translations: {
        index: {
          title: "Solicitar reintegro",
          subTitle: "Puedes procesarlo aquí, de forma rápida, simple y online.",
          subTitle2:
            "Hasta 30 días corridos desde la fecha de vencimiento de tu Certificado de Asistencia.",
          topTac:
            "Al regresar de tu viaje, podrás presentar tu reintegro en un tiempo máximo de 30 días corridos desde la fecha de vencimiento de tu Certificado de Asistencia.",
          bottomTac:
            "Los reintegros de ASSIST 365 son trabajados por un equipo especialista. Para evaluar tu solicitud es imprescindible que nos facilites toda la información completa. Es posible que debamos proporcionar datos e informes a otras personas, como peritos, profesionales de la salud, u otros profesionales que necesitamos para esta tarea. Siempre tu privacidad estará cuidada al máximo.",
          enterDocPass: "Ingresá número de documento o pasaporte",
          enterEmail: "Ingresa tu correo electrónico",
          chooseCert:
            "Selecciona el Certificado por el cual necesitas pedir tu reintegro",
          beneficiaryName: "Nombre del beneficiario",
          validity: "Fechas de vigencia",
          certNumber: "Nro. de certificado",
          fullname: "Nombre y apellido",
          certNumberFull: "Número de certificado de asistencia",
          email: "E-mail",
          select: "Seleccionar",
          payment: {
            title: "Monto total a reintegrar",
            currency: "Moneda",
            total: "Valor total",
          },
          paymentMethod: {
            title: "Medio de pago",
            content:
              "Luego de analizada la solicitud de reintegro, en caso de que sea aprobada, te solicitaremos los <strong>datos bancarios</strong> vía mail para avanzar con el pago.",
          },
          feedback: {
            title: "¿Quieres contarnos algo más sobre lo sucedido?",
            placeholder: "Puedes añadir aquí comentarios sobre lo ocurrido",
          },
          reasonTitle: "Motivo de solicitud de reintegros",
          reasons: {
            0: "Asistencia Médica",
            1: "Odontología",
            2: "Medicamentos",
            3: "Otros",
          },
          reasonsData: {
            subtitle: "Adjuntar documentación respaldatoria",
            uploadRules: {
              1: "Certificado emitido por el médico tratante (con detalle del tratamiento recibido y diagnóstico médico)",
              2: "Factura y comprobantes de pago por honorarios médicos",
              3: "Foto de pasaporte o documento de identidad",
            },
            filesRules: {
              maxFileSize: "Tamaño máximo: {{maxFileSize}} MB(s)",
              maxNumber: "Maximo de {{maxNumber}} archivos",
              acceptType: "Tipos permitidos: {{acceptType}}.",
            },
            selectFiles: "Seleccionar archivos",
            selectOrDrag: "Selecciona o arrastra aquí",
            dragHere: "Suelta aquí",
            update: "Actualizar",
            delete: "Eliminar",
          },
        },
        form: {
          send: "Enviar",
          sending: "Enviando...",
          sendRequest: "Enviar solicitud",
          agree: "Estoy de acuerdo",
          errors: {
            required: "Campo obligatorio",
            upload: "Debes subir al menos un archivo",
            certNotFound:
              "Verifica que el documento ingresado coincida con el que figura en el certificado de asistencia que aplica al reintegro que está solicitando.",
          },
        },
        successModal: {
          title: "Registramos con éxito tu solicitud",
          contentProviderOther:
            "El proceso de evaluación consta de un máximo de 30 días.<br/><br/>Cualquier duda o consulta puedes comunicarte a <a href='mailto: reintegros@assist-365.com'>reintegros@assist-365.com</a>.<br/><br/>Equipo Assist 365.",
          contentProviderWta:
            "Te estaremos enviando a la brevedad a {{email}} las instrucciones para que continúes con la gestión.<br/><br/> Cualquier duda o consulta puedes comunicarte a <a href='mailto: reintegros@assist-365.com'>reintegros@assist-365.com</a>.<br/><br/>Equipo Assist 365 ",
          close: "Cerrar",
        },
      },
    },
  },
  lng: "es-AR",
  fallbackLng: "pt-BR",
  ns: ["translations"],
  defaultNS: "translations",
});
export default i18n;
