import React from "react";
import { useTranslation } from 'react-i18next';
import Block from "../../layout/Blocks";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const Email = () => {

    const { t } = useTranslation();
    const { control, formState: { errors, isSubmitted } } = useFormContext() // retrieve all hook methods

    return (
        <Block title={t("index.enterEmail")}>
            <div className="flex flex-col sm:grid sm:grid-cols-[65%_35%]">
                <Controller
                    control={control}
                    name="email"
                    rules={{ required: t("form.errors.required") }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            hiddenLabel
                            id="standard-start-adornment"
                            size="small"
                            variant="outlined"
                            error={errors?.email && isSubmitted}
                            helperText={errors?.email?.message}
                        />
                    )}
                />
            </div>
        </Block>
    );


};

export default Email;