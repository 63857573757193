import { useTranslation } from "react-i18next";

const UploadRules = ({ config, errors }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="w-full block">
                <ul className="font-inter text-xs list-disc pl-4 md:pl-8 pb-2">
                    <li className={errors?.maxFileSize ? 'text-[#d32f2f]' : ''}>{t("index.reasonsData.filesRules.maxFileSize", { maxFileSize: Math.ceil(config?.maxFileSize / 1024) })}</li>
                    <li className={errors?.maxNumber ? 'text-[#d32f2f]' : ''}>{t("index.reasonsData.filesRules.maxNumber", { maxNumber: config?.maxNumber })}</li>
                    <li className={errors?.acceptType ? 'text-[#d32f2f]' : ''}>{t("index.reasonsData.filesRules.acceptType", { acceptType: config?.acceptType })}</li>
                </ul>
            </div >
        </>
    );
}

export default UploadRules;