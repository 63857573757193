import React from 'react';

const Block = (props) => {

    const { children, title, mode = 'solid', accordion = false } = props;

    return (
        <>
            <div className={`w-full max-w-[630px] md:max-w-[800px] my-2 mx-auto bg-white 
                ${(mode === 'solid' || mode === 'content') ? "shadow-md border border-[#e0e0e0] rounded-md" : ""}
                ${mode === 'content' ? "px-0 md:px-7" : mode === "solid" ? "px-6" : "px-2"}
                ${accordion ? "pt-6 pb-4" : "py-6"}
            `}>
                {title && <h2 className={`leading-tight text-lg text-blue-secondary font-semibold font-titillium pb-3
                    ${mode === 'content' ? 'px-6 md:px-0' : ''}`}>
                    {title}</h2>}
                {children}
            </div>
        </>
    )
}

export default Block;