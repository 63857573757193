import React, { useContext, useEffect, useState } from "react";
import Block from "../../layout/Blocks";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, TextField } from "@mui/material";
import { certByDocumentController } from "../../dataController/dataController";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/StoreReducer";
import { useFormContext } from "react-hook-form";

const Login = ({ submitState }) => {
  const [store, dispatch] = useContext(StoreContext);
  const { reset } = useFormContext();
  const { terms } = store;

  const [passdoc, setPassdoc] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const loadingStyle = {
    color: "white",
    size: 40,
    position: "absolute",
    right: "14px",
  };

  const handleChange = (event) => {
    setPassdoc(event.target.value);
  };

  const onSubmit = () => {
    console.log("on submit");
    setData(null);
    setSubmitted(true);
    submitState(true);

    if (terms) {
      setLoading(true);

      dispatch({
        type: types.setCertData,
        payload: null,
      });
      dispatch({
        type: types.setActiveCert,
        payload: null,
      });
      dispatch({
        type: types.setTerms,
        payload: true,
      });
      reset();

      const fetchData = async () => {
        try {
          const result = await certByDocumentController.fetchData(passdoc);
          const resp = result.data;
          if (resp.status) {
            resp.passport_dni = passdoc;
          }
          setError(!resp?.status);
          setData(resp);
        } catch (error) {
          setError(true);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  };

  useEffect(() => {
    dispatch({
      type: types.setCertData,
      payload: {
        data: data,
      },
    });
  }, [data]);

  const { t } = useTranslation();

  return (
    <Block title={t("index.enterDocPass")}>
      <div className="flex flex-col sm:grid sm:grid-cols-[75%_25%]">
        <TextField
          value={passdoc}
          name="passport_dni"
          onChange={handleChange}
          fullWidth
          hiddenLabel
          error={!passdoc && submitted}
          helperText={!passdoc && submitted && t("form.errors.required")}
          id="standard-start-adornment"
          size="small"
          variant="outlined"
        />
        <Button
          onClick={onSubmit}
          disabled={!passdoc || loading}
          className="btn-core h-[40px] relative ml-0 mt-2 sm:ml-1 sm:mt-0 font-sans font-medium"
          variant="contained"
          disableElevation
          fullWidth
        >
          {loading ? t("form.sending") : t("form.send")}
          {loading && <CircularProgress size={24} style={loadingStyle} />}
        </Button>
        {error && submitted && (
          <span className="text-[#d32f2f] text-xs mt-2">
            {t("form.errors.certNotFound")}
          </span>
        )}
      </div>
    </Block>
  );
};

export default Login;
