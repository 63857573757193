import React, { useContext, useEffect, useState } from "react";
import Block from "../../layout/Blocks";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StoreContext } from "../../store/StoreProvider";

const PersonalData = () => {

    const { t } = useTranslation();
    const [store] = useContext(StoreContext);

    const { cert_data, active_cert } = store;
    const [data, setData] = useState({});

    useEffect(() => {

        const data = cert_data?.data?.cert_list[active_cert];
        const { assignament, firstname, lastname } = data;
        const email = cert_data?.data?.pax_email;
        setData({ assignament, firstname, lastname, email });

    }, [active_cert]);

    return (

        <Block>
            <div className="flex flex-col max-w-full space-y-1 sm:max-w-[65%] gap-4">
                <div>
                    <label className="form-label">{t("index.fullname")}</label>
                    <TextField
                        fullWidth
                        hiddenLabel
                        value={data?.firstname + " " + data?.lastname}
                        id="standard-start-adornment"
                        size="small"
                        variant="outlined"
                    />
                </div>
                <div>
                    <label className="form-label">{t("index.certNumberFull")}</label>
                    <TextField
                        fullWidth
                        hiddenLabel
                        value={data.assignament}
                        id="standard-start-adornment"
                        size="small"
                        variant="outlined"
                    />
                </div>
                <div>
                    <label className="form-label">{t("index.email")}</label>
                    <TextField
                        fullWidth
                        hiddenLabel
                        value={data?.email}
                        id="standard-start-adornment"
                        size="small"
                        variant="outlined"
                    />
                </div>
            </div>
        </Block>
    )
}

export default PersonalData;