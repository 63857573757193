import React, { useState } from 'react';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import { useTranslation } from 'react-i18next';
import refundsGirlComputer from "../../assets/img/refundsGirlComputer.png";
import Login from '../Login';
import CertSelection from '../CertSelection';
import Email from '../Email';
import ButtonSubmit from '../ButtonSubmit';
import PersonalData from '../PersonalData';
import Reasons from '../Reasons';
import Payment from '../Payment';
import Feedback from '../Feedback';
import PaymentMethod from '../PaymentMethod';
import SuccessModal from '../SuccessModal';
import { Button } from '@mui/material';

export function Refunds() {

    const { t } = useTranslation();
    const [openSuccessModal, setOpenSuccessModal] = useState(false);

    const closeSuccessModal = () => {
        setOpenSuccessModal(false);
    }

    return (
        <>

            <SuccessModal open={openSuccessModal} email={"john.doe@email.com"} closeSuccessModal={closeSuccessModal} />
            <div className="py-16 px-4 flex flex-col items-center">
                <h1 className="font-titillium text-3xl md:text-3xl xl:text-4xl text-[#2F6DE0] text-center font-bold pb-12 px-1">
                    {t("index.title")}
                </h1>
                <img className="w-[270px] pb-12" src={refundsGirlComputer} />
                <div className="max-w-[270px] sm:max-w-[852px] text-base md:text-2xl font-medium text-center font-titillium mb-8">
                    <h2>{t("index.subTitle")}</h2>
                    <h2>{t("index.subTitle2")}</h2>
                </div>
                <TermsAndConditions tacTarget="top" />
                <Login />
                <CertSelection />
                <Email />
                <PersonalData />
                <Reasons />
                <Payment />
                <Feedback />
                <PaymentMethod />
                <TermsAndConditions tacTarget="bottom" />
                <ButtonSubmit />
                <a href="#" onClick={() => setOpenSuccessModal(!openSuccessModal)}
                    className="underline ml-0 my-9 p-4 max-w-80 font-sans capitalize text-xl font-semibold">
                    Test Modal
                </a>
            </div>
        </>
    );
}