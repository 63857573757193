import { Refunds } from "./components/Refunds";
import "./assets/css/tailwind.css";
import "./assets/css/core.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { LanguageProvider } from "./context/LanguageContext";
import StoreProvider from "./store/StoreProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoaderProvider } from "./context/loaderContext";

export default function Root(props) {
  const theme = createTheme({
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: "red",
            margin: 0,
            padding: 0,
          },
        },
      },
    },
  });

  return (
    <LanguageProvider>
      <I18nextProvider i18n={i18n}>
        <LoaderProvider>
          <StoreProvider>
            <ThemeProvider theme={theme}>
              <Refunds />
            </ThemeProvider>
          </StoreProvider>
        </LoaderProvider>
      </I18nextProvider>
    </LanguageProvider>
  );
}
