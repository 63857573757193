import React from "react";
import Block from "../../layout/Blocks";
import { useTranslation } from "react-i18next";
import { Box, Button, TextField } from "@mui/material";

const Login = () => {

    const { t } = useTranslation();

    return (
        <Block title={t("index.enterDocPass")}>
            <div className="flex flex-col sm:grid sm:grid-cols-[75%_25%]">
                <TextField
                    fullWidth
                    hiddenLabel
                    id="standard-start-adornment"
                    size="small"
                    variant="outlined"
                />
                <Button className="ml-0 mt-2 sm:ml-1 sm:mt-0 font-sans font-medium" variant="contained" disableElevation fullWidth>
                    {t("form.send")}
                </Button>
            </div>
        </Block >
    )
}

export default Login;