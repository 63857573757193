import React, { useState } from "react";
import ImageUploading from 'react-images-uploading';
import Block from "../../layout/Blocks";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from "react-i18next";
import { Button, Checkbox } from "@mui/material";
import MediaQuery from "react-responsive";

const Reasons = () => {

    const { t } = useTranslation();
    const [images, setImages] = React.useState([]);
    const [images2, setImages2] = React.useState([]);
    const [images3, setImages3] = React.useState([]);
    const [images4, setImages4] = React.useState([]); ``
    const maxNumber = 4;

    const initialState = {
        1: false,
        2: false,
        3: false,
        4: false,
    }

    const [checked, setChecked] = useState(initialState);

    const handleChange = (panel) => {
        const preChecked = { 1: false, 2: false, 3: false, 4: false };
        Object.keys(preChecked).map(v => {
            if (v == panel) {
                preChecked[v] = true;;
            } else {
                preChecked[v] = false;
            }
        });
        setChecked(preChecked);
    }

    const onChange = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    const onChange2 = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setImages2(imageList);
    };

    const onChange3 = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setImages3(imageList);
    };

    const onChange4 = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setImages3(imageList);
    };

    const onSubmit = () => {
        console.log('images', images);
        console.log('images2', images2);
    }

    return (
        <Block mode="content" title={t("index.reasonTitle")}>

            <div>
                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        <>
                            <Accordion disableGutters>
                                <AccordionSummary
                                    onClick={() => handleChange(1)}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        padding: "0 0.25rem", margin: 0,
                                        "& .MuiAccordionSummary-content": {
                                            margin: "0"
                                        }

                                    }}
                                >
                                    <div className="px-[0.5rem] flex items-center justify-start">
                                        <Checkbox
                                            checked={checked[1]}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        {t("index.reasons.1")}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <h2 className="font-titillium text-base font-bold text-blue-secondary px-0 sm:px-3">{t("index.reasonsData.subtitle")}</h2>
                                    <ul className="list-disc space-y-1 sm:space-y-2 text-sm my-4 ml-4 px-0 sm:px-3">
                                        <li>{t("index.reasonsData.uploadRules.1")}</li>
                                        <li>{t("index.reasonsData.uploadRules.2")}</li>
                                        <li>{t("index.reasonsData.uploadRules.3")}</li>
                                    </ul>
                                    <div className="upload__image-wrapper">
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item flex items-center justify-start">
                                                <div className="image__container ml-0 sm:ml-4 mr-2 sm:mr-8">
                                                    <img src={image['data_url']} alt="" />
                                                </div>
                                                <div className="image-item__btn-wrapper">
                                                    <button onClick={() => onImageUpdate(index)} className="btn__update">{t("index.reasonsData.update")}</button>
                                                    <button onClick={() => onImageRemove(index)} className="btn__remove">{t("index.reasonsData.delete")}</button>
                                                </div>
                                            </div>
                                        ))}

                                        <Button
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            sx={{
                                                backgroundColor: isDragging ? "#EEEEEE" : "#2F6DE0",
                                                color: isDragging ? "#888" : "#FFF",
                                                border: isDragging ? "2px dashed #999" : "2px solid #2F6DE0",

                                            }}
                                            className="my-4 max-w-80 font-sans font-medium ml-0 sm:ml-3"
                                            variant="contained"
                                            disableElevation
                                            fullWidth>
                                            {isDragging ? t("index.reasonsData.dragHere") :
                                                <>
                                                    <MediaQuery minWidth={620}>{t("index.reasonsData.selectOrDrag")}</MediaQuery>
                                                    <MediaQuery maxWidth={619}>{t("index.reasonsData.selectFiles")}</MediaQuery>
                                                </>
                                            }
                                        </Button>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            {errors &&
                                <div>
                                    {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
                                    {errors.acceptType && <span>Your selected file type is not allow</span>}
                                    {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
                                    {errors.resolution && <span>Selected file is not match your desired resolution</span>}
                                </div>}
                        </>
                    )}
                </ImageUploading>

                <ImageUploading
                    multiple
                    value={images2}
                    onChange={onChange2}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        <>
                            <Accordion disableGutters>
                                <AccordionSummary
                                    onClick={() => handleChange(2)}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    sx={{
                                        padding: "0 0.25rem", margin: 0,
                                        "& .MuiAccordionSummary-content": {
                                            margin: "0"
                                        }

                                    }}
                                >
                                    <div className="px-[0.5rem] flex items-center justify-start">
                                        <Checkbox
                                            checked={checked[2]}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        {t("index.reasons.2")}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <h2 className="font-titillium text-base font-bold text-blue-secondary px-0 sm:px-3">{t("index.reasonsData.subtitle")}</h2>
                                    <ul className="list-disc space-y-1 sm:space-y-2 text-sm my-4 ml-4 px-0 sm:px-3">
                                        <li>{t("index.reasonsData.uploadRules.1")}</li>
                                        <li>{t("index.reasonsData.uploadRules.2")}</li>
                                        <li>{t("index.reasonsData.uploadRules.3")}</li>
                                    </ul>
                                    <div className="upload__image-wrapper">
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item flex items-center justify-start">
                                                <div className="image__container ml-0 sm:ml-4 mr-2 sm:mr-8">
                                                    <img src={image['data_url']} alt="" />
                                                </div>
                                                <div className="image-item__btn-wrapper">
                                                    <button onClick={() => onImageUpdate(index)} className="btn__update">{t("index.reasonsData.update")}</button>
                                                    <button onClick={() => onImageRemove(index)} className="btn__remove">{t("index.reasonsData.delete")}</button>
                                                </div>
                                            </div>
                                        ))}

                                        <Button
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            sx={{
                                                backgroundColor: isDragging ? "#EEEEEE" : "#2F6DE0",
                                                color: isDragging ? "#888" : "#FFF",
                                                border: isDragging ? "2px dashed #999" : "2px solid #2F6DE0",

                                            }}
                                            className="my-4 max-w-80 font-sans font-medium ml-0 sm:ml-3"
                                            variant="contained"
                                            disableElevation
                                            fullWidth>
                                            {isDragging ? t("index.reasonsData.dragHere") :
                                                <>
                                                    <MediaQuery minWidth={620}>{t("index.reasonsData.selectOrDrag")}</MediaQuery>
                                                    <MediaQuery maxWidth={619}>{t("index.reasonsData.selectFiles")}</MediaQuery>
                                                </>
                                            }
                                        </Button>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            {errors &&
                                <div>
                                    {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
                                    {errors.acceptType && <span>Your selected file type is not allow</span>}
                                    {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
                                    {errors.resolution && <span>Selected file is not match your desired resolution</span>}
                                </div>}
                        </>
                    )}
                </ImageUploading>

                <ImageUploading
                    multiple
                    value={images3}
                    onChange={onChange3}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        <>
                            <Accordion disableGutters>
                                <AccordionSummary
                                    onClick={() => handleChange(3)}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    sx={{
                                        padding: "0 0.25rem", margin: 0,
                                        "& .MuiAccordionSummary-content": {
                                            margin: "0"
                                        }

                                    }}
                                >
                                    <div className="px-[0.5rem] flex items-center justify-start">
                                        <Checkbox
                                            checked={checked[3]}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        {t("index.reasons.3")}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <h2 className="font-titillium text-base font-bold text-blue-secondary px-0 sm:px-3">{t("index.reasonsData.subtitle")}</h2>
                                    <ul className="list-disc space-y-1 sm:space-y-2 text-sm my-4 ml-4 px-0 sm:px-3">
                                        <li>{t("index.reasonsData.uploadRules.1")}</li>
                                        <li>{t("index.reasonsData.uploadRules.2")}</li>
                                        <li>{t("index.reasonsData.uploadRules.3")}</li>
                                    </ul>
                                    <div className="upload__image-wrapper">
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item flex items-center justify-start">
                                                <div className="image__container ml-0 sm:ml-4 mr-2 sm:mr-8">
                                                    <img src={image['data_url']} alt="" />
                                                </div>
                                                <div className="image-item__btn-wrapper">
                                                    <button onClick={() => onImageUpdate(index)} className="btn__update">{t("index.reasonsData.update")}</button>
                                                    <button onClick={() => onImageRemove(index)} className="btn__remove">{t("index.reasonsData.delete")}</button>
                                                </div>
                                            </div>
                                        ))}

                                        <Button
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            sx={{
                                                backgroundColor: isDragging ? "#EEEEEE" : "#2F6DE0",
                                                color: isDragging ? "#888" : "#FFF",
                                                border: isDragging ? "2px dashed #999" : "2px solid #2F6DE0",

                                            }}
                                            className="my-4 max-w-80 font-sans font-medium ml-0 sm:ml-3"
                                            variant="contained"
                                            disableElevation
                                            fullWidth>
                                            {isDragging ? t("index.reasonsData.dragHere") :
                                                <>
                                                    <MediaQuery minWidth={620}>{t("index.reasonsData.selectOrDrag")}</MediaQuery>
                                                    <MediaQuery maxWidth={619}>{t("index.reasonsData.selectFiles")}</MediaQuery>
                                                </>
                                            }
                                        </Button>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            {errors &&
                                <div>
                                    {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
                                    {errors.acceptType && <span>Your selected file type is not allow</span>}
                                    {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
                                    {errors.resolution && <span>Selected file is not match your desired resolution</span>}
                                </div>}
                        </>
                    )}
                </ImageUploading>

                <ImageUploading
                    multiple
                    value={images4}
                    onChange={onChange4}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        <>
                            <Accordion disableGutters>
                                <AccordionSummary
                                    onClick={() => handleChange(4)}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                    sx={{
                                        padding: "0 0.25rem", margin: 0,
                                        "& .MuiAccordionSummary-content": {
                                            margin: "0"
                                        }

                                    }}
                                >
                                    <div className="px-[0.5rem] flex items-center justify-start">
                                        <Checkbox
                                            checked={checked[4]}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        {t("index.reasons.4")}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <h2 className="font-titillium text-base font-bold text-blue-secondary px-0 sm:px-3">{t("index.reasonsData.subtitle")}</h2>
                                    <ul className="list-disc space-y-1 sm:space-y-2 text-sm my-4 ml-4 px-0 sm:px-3">
                                        <li>{t("index.reasonsData.uploadRules.1")}</li>
                                        <li>{t("index.reasonsData.uploadRules.2")}</li>
                                        <li>{t("index.reasonsData.uploadRules.3")}</li>
                                    </ul>
                                    <div className="upload__image-wrapper">
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item flex items-center justify-start">
                                                <div className="image__container ml-0 sm:ml-4 mr-2 sm:mr-8">
                                                    <img src={image['data_url']} alt="" />
                                                </div>
                                                <div className="image-item__btn-wrapper">
                                                    <button onClick={() => onImageUpdate(index)} className="btn__update">{t("index.reasonsData.update")}</button>
                                                    <button onClick={() => onImageRemove(index)} className="btn__remove">{t("index.reasonsData.delete")}</button>
                                                </div>
                                            </div>
                                        ))}

                                        <Button
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            sx={{
                                                backgroundColor: isDragging ? "#EEEEEE" : "#2F6DE0",
                                                color: isDragging ? "#888" : "#FFF",
                                                border: isDragging ? "2px dashed #999" : "2px solid #2F6DE0",

                                            }}
                                            className="my-4 max-w-80 font-sans font-medium ml-0 sm:ml-3"
                                            variant="contained"
                                            disableElevation
                                            fullWidth>
                                            {isDragging ? t("index.reasonsData.dragHere") :
                                                <>
                                                    <MediaQuery minWidth={620}>{t("index.reasonsData.selectOrDrag")}</MediaQuery>
                                                    <MediaQuery maxWidth={619}>{t("index.reasonsData.selectFiles")}</MediaQuery>
                                                </>
                                            }
                                        </Button>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            {errors &&
                                <div>
                                    {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
                                    {errors.acceptType && <span>Your selected file type is not allow</span>}
                                    {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
                                    {errors.resolution && <span>Selected file is not match your desired resolution</span>}
                                </div>}
                        </>
                    )}
                </ImageUploading>

                {/* <button onClick={onSubmit}>Submit</button> */}
            </div>
        </Block>
    )
}

export default Reasons;