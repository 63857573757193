import React from "react";
import Block from "../../layout/Blocks";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";


const PersonalData = () => {

    const { t } = useTranslation();

    return (

        <Block>

            <div className="flex flex-col max-w-full space-y-1 sm:max-w-[65%] gap-4">
                <div>

                    <label className="form-label">{t("index.fullname")}</label>
                    <TextField
                        fullWidth
                        hiddenLabel
                        id="standard-start-adornment"
                        size="small"
                        variant="outlined"
                    />
                </div>
                <div>
                    <label className="form-label">{t("index.certNumberFull")}</label>
                    <TextField
                        fullWidth
                        hiddenLabel
                        id="standard-start-adornment"
                        size="small"
                        variant="outlined"
                    />
                </div>
                <div>
                    <label className="form-label">{t("index.email")}</label>
                    <TextField
                        fullWidth
                        hiddenLabel
                        id="standard-start-adornment"
                        size="small"
                        variant="outlined"
                    />
                </div>
            </div>
        </Block>
    )
}

export default PersonalData;