import React from "react";
import { useTranslation } from 'react-i18next';
import Block from "../../layout/Blocks";
import { TextField } from "@mui/material";

const Email = () => {

    const { t } = useTranslation();

    return (
        <Block title={t("index.enterEmail")}>
            <div className="flex flex-col sm:grid sm:grid-cols-[65%_35%]">
                <TextField
                    fullWidth
                    hiddenLabel
                    id="standard-start-adornment"
                    size="small"
                    variant="outlined"
                />
            </div>
        </Block>
    );


};

export default Email;